import React, { useEffect, useState } from "react";

import {
  Box, Button, Dialog, DialogContent, Grid, List, ListItem, ListItemIcon, ThemeProvider, Typography, createTheme, styled, Card,
  CardMedia,
  CardContent,
  withStyles,
  LinearProgress,
  DialogContentText
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import { ConfirmImage } from "../../blocks/user-profile-basic/src/assets";
import {
  calender,
  lessonImage, image_doc,
  image_play_card,
  image_complete_card,
  image_Vector,
  congurlaction,
  pdfimage,
  imagePlain
} from "../../blocks/assessmenttest/src/assets";
import moment from "moment";
import { NavigateNext } from "@material-ui/icons";
import { DialogWrapper } from "../../blocks/assessmenttest/src/AssessmentQuestions/AssessmentQuestions.web";


type Props = {
  navigation: any;
  items: {
    img: any;
    name: string;
    link?: string;
    subLinks?: string[];
    style?: Record<string, string | number>;
    imgStyle?: Record<string, string | number>;
  }[];
};

export const sideBarItems: Props["items"] = [
  {
    img: [
      require("../assets/Home-New.png"),
      require("../assets/Home-Active-New.png"),
    ],
    name: "Home",
    link: "/Dashboard",
    subLinks: ["myprofile", "editprofile"],
    imgStyle: { height: 30, width: 30 }
  },
  {
    img: [
      require("../assets/rewards.svg"),
      require("../assets/rewardsActive.svg"),
    ],
    name: "Rewards",
    link: "/Rewards",
    imgStyle: { height: 30, width: 30 }
  },
  {
    img: [require("../assets/analytics.svg"), require("../assets/analytics-active.svg")],
    name: "Analytics",
    link: "/Analytics",
    imgStyle: { height: 26, width: 26 }
  },
  {
    img: [require("../assets/Audit-New.png"), require("../assets/Audit-Active-New.png")],
    name: "Audit",
    link: "/Audit",
    imgStyle: { height: 30, width: 30 }
  },
  {
    img: [require("../assets/Planned-New.png"), require("../assets/Planned-Active-New.png")],
    name: "Planned Inspection",
    link: "/PlannedInspection",
    imgStyle: { height: 30, width: 30 }
  },
  {
    img: [require("../assets/SafetyIncharge-New.png"), require("../assets/SaftyIncharge-Active-New.png")],
    name: "Safety Council",
    link: "/SafetyCouncilPost",
    imgStyle: { height: 30, width: 30 }
  },
  {
    img: [require("../assets/MyPost-New.png"), require("../assets/MyPost-Active-New.png")],
    name: "My Posts",
    link: "/MyPosts",
    imgStyle: { height: 30, width: 30 }
  },
  {
    img: [require("../assets/microLearning.svg"), require("../assets/microLearning-active.svg")],
    name: "Micro Learning",
    link: "/AssessmentTest",
    imgStyle: { height: 29, width: 29 }
  },
  {
    img: [require("../assets/MyPost-New.png"), require("../assets/MyPost-Active-New.png")],
    name: "Publish a Post",
    link: "/PublishPost",
    imgStyle: { height: 30, width: 30 }
  },
  {
    img: [
      require("../assets/ActionTracker-New.png"),
      require("../assets/ActionTracker-Active-New.png"),
    ],
    name: "Action Tracker",
    link: "/PerformanceTracker",
    imgStyle: { height: 30, width: 30 }
  },
  {
    img: [
      require("../assets/Settings-New.png"),
      require("../assets/Settings-Active-New.png"),
    ],
    name: "Account",
    link: "/Account",
    imgStyle: { height: 30, width: 30 }
  },
  {
    img: [require("../assets/Help-New.png"), require("../assets/Help-Active-New.png")],
    name: "Help",
    link: "/Help",
    imgStyle: { height: 30, width: 30 }
  },
];

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: '8px',
        overflowX: "hidden"
      },
    }
  }
});

export default class SidebarMenu extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      confirmPopUp: false,
    }
  }
  logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  };

  setLogOutPopUP = () => {
    this.setState({ confirmPopUp: true })
  }

  closeLogout = () => {
    this.setState({ confirmPopUp: false })
  }

  render() {
    return (
      <Wrapper>
        <ThemeProvider theme={theme}>
          <List className={"mainList"}>
            <div>
              {sideBarItems.map(({ style = {}, imgStyle = {}, name, link, img, subLinks = [] }, index) => (
                <SvgImageRander style={style} imgStyle={imgStyle} name={name} index={index} link={link} img={img} subLinks={subLinks} />
              ))}
            </div>

            <div>
              <ListItem
                button
                dense
                data-test-id="Logout-button"
                style={
                  {
                    ...webStyles.menuButton,
                  } as React.CSSProperties
                }
                onClick={this.setLogOutPopUP}
              >
                <ListItemIcon>
                  <img style={{ paddingLeft: '5px' }} src={require("../assets/logout.png")} />
                </ListItemIcon>
                <p style={{ ...webStyles.menuText, color: "#F30202" }}>Logout</p>
              </ListItem>
            </div>
          </List>
          {/* @ts-ignore */}
          {this.state.confirmPopUp &&
            <Dialog
              style={{ borderRadius: '8px' }}
              maxWidth="md"
              open={true}
            >
              <CloseIcon style={{ float: 'right', alignSelf: 'flex-end', padding: '20px 20px 0px 20px', cursor: 'pointer' }} onClick={this.closeLogout} />
              <DialogContent style={webStyles.confirmationText as React.CSSProperties}>
                <Box style={webStyles.iconContainer} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                  <img src={ConfirmImage} />
                </Box>
                <Typography style={webStyles.confirmText} align="center">Are you sure want to logout?</Typography>
                <Grid container style={{ padding: '0px 10px' }}>
                  <Button onClick={this.closeLogout} variant="outlined" data-test-id="handleClose" style={webStyles.cancelBtn as React.CSSProperties}>
                    Cancel
                  </Button>
                  <Button onClick={this.logout} variant="contained" data-test-id="handleClose" style={webStyles.logOutBtn as React.CSSProperties}>
                    Yes, Logout
                  </Button>
                </Grid>
              </DialogContent>
            </Dialog>
          }
        </ThemeProvider>
      </Wrapper>
    );
  }
}

export const SvgImageRander = ({ style, imgStyle, name, index, link, img, subLinks }: any) => {
  let paths = window.location.pathname.split('/')
  const isActive = (
    paths[1].toLowerCase() === link?.split("/")[1].toLowerCase() ||
    paths[2]?.toLowerCase() === link?.split("/")[1].toLowerCase() ||
    subLinks.includes(paths[1].toLowerCase())
  );

  return (
    <NavLink
      to={String(link)}
      key={index}
      style={{ textDecoration: "none" }}
      activeClassName="active"
    >
      <ListItem
        dense
        key={name}
        data-test-id="list-button"
        style={{
          ...webStyles.menuButton,
          ...style,
        }}
      >
        <ListItemIcon>
          {isActive ? (
            <img
              src={img[1]}
              style={imgStyle}
            />
          ) : (
            <img
              src={img[0]}
              style={imgStyle}
            />
          )}
        </ListItemIcon>
        <p
          style={{
            ...webStyles.menuText,
            ...(isActive
              ? webStyles.active
              : ""),
          }}
        >
          {name}
        </p>
      </ListItem>
    </NavLink>
  );
};

const BorderLinearProgress = withStyles((theme: any) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#8735E1',
  },
}))(LinearProgress);


export const AssessmentDetailheadingWrapper: any = ({ Courses, navigateToAssessmentTestScreen }: any) => {
  return (
    <>
      <Box style={webStyles.breadcrumbWrapper}>
        <Typography className="subHeadingText" data-test-id="navigate_to_audit_screen" onClick={() => navigateToAssessmentTestScreen()}>Learning</Typography>
        <Typography className="subHeadingText">
          <NavigateNext className="navigateNext" />
        </Typography>
        <Typography className="subHeadingText activeHeadingText">{Courses?.attributes?.course_name}</Typography>
      </Box>

      <Box className="analyticTitle">
        <Typography className="mainHeading">{Courses?.attributes?.course_name}</Typography>
      </Box>
    </>
  )
}

export const AssessmentDetailCard: any = ({ Courses, navigateToAssessmentQuestionScreen }: any) => {
  const [buttonLabel, setButtonLabel] = useState("Start");
  const [progress, setProgress] = useState(0);

  const calculateButtonLabel = () => {
    const statusArray = [
      Courses?.attributes?.lessons?.data?.map((lesson: any) => lesson.attributes.completed),
      Courses?.attributes?.quizzes?.data?.map((quiz: any) => quiz.attributes.passed),
    ].flat();

    if (statusArray.every(status => status)) {
      return "Completed";
    } else if (statusArray.some(status => status)) {
      return "Resume Course";
    } else {
      return "Start";
    }
  };

  const calculateProgress = () => {
    const lessons = Courses?.attributes?.lessons?.data || [];
    const quizzes = Courses?.attributes?.quizzes?.data || [];
    const totalItems = lessons.length + quizzes.length;
    const completedLessons = lessons.filter((lesson: any) => lesson.attributes.completed).length;
    const passedQuizzes = quizzes.filter((quiz: any) => quiz.attributes.passed).length;
    const completedItems = completedLessons + passedQuizzes;
    return totalItems > 0 ? (completedItems / totalItems) * 100 : 0;
  };


  useEffect(() => {
    const label = calculateButtonLabel();
    const calculate_Progress = calculateProgress();
    setButtonLabel(label);
    setProgress(calculate_Progress);
  }, [Courses]);


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} key="1">
          <Card className="courseCardStyle" data-test-id="course_card">
            <CardMedia component="img" height="200px" image={Courses?.attributes?.course_image} alt="CardMedia" />

            <CardContent className="CardContent">
              <Box className="cardContentMainBox">
                <Typography className="courseCardMainHeading">{Courses?.attributes?.description}</Typography>
              </Box>

              <Box className="coursecarddetail">
                <Box className="imageAndText lessons_count">
                  <img src={lessonImage} alt="lessons" height={18} width={18} />
                  <Typography className="trimWords cardSubText">{`${Courses?.attributes?.lessons_size} Lessons`} </Typography>
                </Box>
                <Box className="imageAndText">
                  <img src={calender} alt="calendar" height={18} width={18} />
                  <Typography className="trimWords cardSubText">{`Complete By: ${moment(Courses?.attributes?.completed_by).format('DD/MM/YYYY')}`}</Typography>
                </Box>
              </Box>

              <Box className="firewrapper" style={{ flexWrap: "wrap" }}>
                {Courses?.attributes?.broad_risk_categories.map((broad_risk_categories: any) => {
                  return (
                    <Box className="imageAndText" >
                      <Typography className="trimWords cardSubText firetext">{broad_risk_categories}</Typography>
                    </Box>
                  )
                })}
              </Box>
              <Box className="LinearProgress">
                <Box className="LinearProgressbox">
                  <BorderLinearProgress variant="determinate" value={progress} />
                </Box>
                <Typography className="completed-text">{`${Math.round(progress)}% Completed`}</Typography>
              </Box>

              <Button className="resume-btn" onClick={() => navigateToAssessmentQuestionScreen()}>
                {buttonLabel}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export const AssessmentDetailOverview = ({ Courses }: any) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (Courses && Courses.attributes) {
      const lessons = Array.isArray(Courses.attributes.lessons?.data) ? Courses.attributes.lessons.data.map((lesson: any) => ({
        id: lesson.id,
        type: lesson.type,
        attributes: lesson.attributes,
      })) : [];

      const quizzes = Array.isArray(Courses.attributes.quizzes?.data) ? Courses.attributes.quizzes.data.map((quiz: any) => ({
        id: quiz.id,
        type: quiz.type,
        attributes: quiz.attributes,
      })) : [];

      setData([...lessons, ...quizzes]);

    }
  }, [Courses]);

  return (
    <>
      <Box>
        <Typography className="course-detail">Content Details </Typography>
      </Box>
      <Box>
        {data && data.map((lessons: any, index: number) => {
          return (
            <Grid item xs={12} sm={12} md={12} key={index}>
              <Card className="courseCardStyle" data-test-id="course_card">
                <CardContent className="courseCard">
                  <Box className="cardContentMainBox">
                    <Typography className="courseCardMainHeading trimWords">
                      <span style={{ marginRight: "9px" }}>
                        {lessons?.attributes?.lesson_type === "video" ? <img style={{ width: "20px" }} src={image_play_card} alt="" /> :
                          lessons?.attributes?.lesson_type === "document" ? <img style={{ width: "20px" }} src={pdfimage} alt="PDF" /> :
                            lessons?.type === "quizzes" ? <img style={{ width: "20px" }} src={image_Vector} alt="quizzes" /> :
                              lessons?.attributes?.lesson_type === "text" ? <img style={{ width: "20px" }} src={image_doc} alt="Doc" /> :
                                lessons?.attributes?.lesson_type === "image" ? <img style={{ width: "20px" }} src={imagePlain} alt="image" />
                                  : ""}
                      </span>
                      {lessons?.attributes?.lesson_name}{lessons?.attributes?.quiz_name}
                    </Typography>
                    {(lessons?.attributes?.completed || lessons?.attributes?.passed) && <img style={{ width: "20px" }} src={image_complete_card} alt="image_complete_card" />}
                  </Box>
                  <Box className="cardContentMainBox">
                    <Typography className="cardDescription">
                      {lessons?.attributes?.description}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Box>
    </>)
}

export const AssessmentTestCard: any = ({ course, navigateToAssessmentDetail }: any) => {
  return (
    <>
      {course?.map((courses: any, index: any) => {
        return (<>
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card className={`courseCardStyle ${courses?.attributes?.is_mandatory ? "courseCardStyleSelected" : " "}`}
              onClick={() => navigateToAssessmentDetail(courses?.id)}
              data-test-id="course_card">
              <CardMedia
                component="img"
                height="200px"
                image={courses["attributes"]["course_image"]}
              />
              <CardContent>
                {courses?.attributes?.is_mandatory && (<Box className="cardContentStar">
                  <StarOutlineIcon />
                </Box>)}
                <Box className="cardContentMainBox">
                  <Typography className="courseCardMainHeading trimWords">{courses["attributes"]["course_name"]}</Typography>
                </Box>
                <Box className="cardContentMainBox">
                  <Typography className="cardDescription">{courses["attributes"]["description"]}</Typography>
                </Box>
                <Box className="imageAndText">
                  <img src={lessonImage} alt="lessons" height={18} width={18} />
                  <Typography className="trimWords cardSubText">{`${courses["attributes"]["lessons_size"]} Lessons`}</Typography>
                </Box>
                <Box className="imageAndText">
                  <img src={calender} alt="calendar" height={18} width={18} />
                  <Typography className="trimWords cardSubText">{`Complete By: ${moment(courses["attributes"]["completed_by"]).format('DD/MM/YYYY')}`}</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </>)
      })}
    </>
  )
}

export const Breadcrumbs = (props: any) => {
  const subHeading = props.lessons_all_data.attributes.course_name ?? "";
  const name = props.Breadcrumbs_name ?? "";
  useEffect(() => { }, [props])
  return (
    <>
      <Box style={webStyles.breadcrumbWrapper}>
        <Typography style={webStyles.breadcrumbItem} data-test-id="navigate_to_audit_screen" onClick={() => props.navigateToAssessmentTestScreen()} >Learning</Typography>
        <NavigateNext style={webStyles.navigateIcon} />
        <Typography style={webStyles.breadcrumbItem} onClick={() => props.navigateToAssessmentDetail()}>{subHeading}</Typography>
        <NavigateNext style={webStyles.navigateIcon} />
        <Typography style={webStyles.breadcrumbActiveItem} >{name}</Typography>
      </Box>
    </>
  )
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className: any
}
export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export const ButtonComponent = (props: any) => {
  return (
    <>
      <Box className="resume-btn-wrapper">
        {(props.state.value !== 0) ?
          <Button
            className={props.state.value === 0 ? "buttonStyle2Disabled " : "resume-btn resume-btn1"}
            onClick={props.handlePrevious}
            disabled={props.state.value === 0}
          >
            Previous
          </Button>
          : <Typography></Typography>
        }
        <Box className="resume-parent">
          {props.type !== "quizzes" ?
            <Button
              className={props.markAsComplete ? "buttonStyle2Disabled" : "resume-btn resume-btn1"}
              onClick={props.handleMarkAsComplete}
              disabled = {props.markAsComplete ? true: false}
            >
              Mark as Complete
            </Button> : <Typography></Typography>
          }
          <Button
            className="resume-btn"
            onClick={() => {
              (props.state.value === props.state.lessons_page_sidebar.length - 1) && props.handleOpencongratulationsModel(true);
              props.handleNext()
            }}
          // disabled={props.state.value === props.state.lessons_page_sidebar.length - 1}
          >
            {(props.state.value === props.state.lessons_page_sidebar.length - 1) ? "Finish" : "Next"}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export const CongratulationsModal = (props: any) => {
  const { open, congratulationsData } = props;
  return (
    <>
      <DialogWrapper className="congratulationsModal">
        <Dialog aria-labelledby="simple-dialog-title" open={open}>
          <DialogContent style={{ padding: '30px' }}>
            <img src={congurlaction} className="congImg" alt="" style={{ height: '86px', margin: '50px auto 20px auto', display: 'block' }} />

            <DialogContentText id="alert-dialog-description">
              <Typography style={{ fontSize: '24px', fontWeight: 700, width: "100%", textAlign: 'center', marginBottom: '10px', color: '#111' }}>{`Congrats ${congratulationsData?.user_course_quiz?.data?.attributes?.account?.data?.attributes?.full_name}`}</Typography>
              <Typography style={{ fontSize: '24px', fontWeight: 400, width: "100%", textAlign: 'center', marginBottom: '20px', color: '#111' }}>
                {congratulationsData.message}
              </Typography>

              <Box style={{ background: "#FAF3FD", padding: '15px', border: '1px solid #8735E1', borderRadius: '8px' }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between', color: '#111' }}>
                  <Typography style={{ fontSize: '20px' }}>
                    Contribution
                  </Typography>
                  <Typography style={{ fontSize: '24px' }}>
                    <strong>{congratulationsData.current_contribution_points ?? "Coming Soon ..."}</strong> Points
                  </Typography>
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'space-between', color: "#111" }}>
                  <Typography style={{ fontSize: '20px' }}>
                    Level <strong>{"Coming Soon ..."}</strong>
                  </Typography>
                  <Typography style={{ fontSize: '24px' }}>
                    {congratulationsData?.level_data?.level_data ?? "Coming Soon ..."}
                  </Typography>
                </Box>
              </Box>
            </DialogContentText>

            <Button onClick={() => props.navigateToAssessmentTestScreen()} style={{ background: '#8735E1', padding: '10px', width: '100%', fontSize: '20px', fontWeight: 700, color: '#fff', textTransform: 'capitalize', marginTop: '100px' }}>Finish Next Course To Earn 5000 Points </Button>
            <Button onClick={() => props.navigateToAssessmentTestScreen()} style={{ background: '#fff', padding: '10px', width: '100%', fontSize: '20px', fontWeight: 700, color: '#8735E1', textTransform: 'capitalize', marginTop: '15px', border: '1px solid #8735E1' }}>Back to Home</Button>
          </DialogContent>
        </Dialog>
      </DialogWrapper>
    </>
  )
}

export const CustomTabContentImagesDynamicLoad = ({question ,index}:any) => {
  const { question_option_image_a, question_option_image_b, question_option_image_c, question_option_image_d, question_option_image_name_a, question_option_image_name_b, question_option_image_name_c, question_option_image_name_d, answer_option_a, answer_option_b, answer_option_c, answer_option_d } = question.attributes;
  return (
    <>
      <Typography className="questiontext">{question.attributes.question}</Typography>
      {(question_option_image_a.length || question_option_image_b.length || question_option_image_c.length || question_option_image_d.length) ? <Grid container spacing={3} className="">

        {question_option_image_a.length ? <Grid item md={3} className="marginBottom">
          <Box className="quiz-card-img">
          <div className="quiz-img">
            <img src={question_option_image_a} alt="no image" className="Qimag" />
            <Typography className="Quiz_options">{question_option_image_name_a}</Typography>
          </div>
          </Box>
        </Grid> : ""}

        {question_option_image_b.length ? <Grid item md={3} className="marginBottom">
          <Box className="quiz-card-img">
            <div className="quiz-img">
              <img src={question_option_image_b} alt="" className="Qimag" />
            </div>
            <Typography className="Quiz_options">{question_option_image_name_b}</Typography>
          </Box>
        </Grid> : ""}

        {question_option_image_c.length ? <Grid item md={3} className="marginBottom">
          <Box className="quiz-card-img">
            <div className="quiz-img">
              <img src={question_option_image_c} alt=""  className="Qimag"/>
            </div>
            <Typography className="Quiz_options">{question_option_image_name_c}</Typography>
          </Box>
        </Grid> : ""}

        {question_option_image_d.length ? <Grid item md={3} className="marginBottom">
          <Box className="quiz-card-img">
            <div className="quiz-img">
              <img src={question_option_image_d} alt=""  className="Qimag" />
            </div>
            <Typography className="Quiz_options">{question_option_image_name_d}</Typography>
          </Box>
        </Grid> : ""}
      </Grid> : ""}
    </>
  )
}


export const TabIcon = ({ lessonType, type }:any) => {
  let imageSrc = '';

  if (lessonType === 'video') {
    imageSrc = image_play_card;
  } else if (lessonType === 'document') {
    imageSrc = pdfimage;
  } else if (type === 'quizzes') {
    imageSrc = image_Vector;
  } else if (lessonType === 'text') {
    imageSrc = image_doc;
  } else if (lessonType === 'image') {
    imageSrc = imagePlain
  }

  return (
    <>
      {imageSrc && <img style={{ width: '20px' }} src={imageSrc} alt="" />}
    </>
  );
};


const webStyles = {
  iconContainer: {
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    backgroundColor: '#fee6e6',
  },
  confirmText: {
    padding: '30px 0px',
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    lineHeight: '22px',
  },
  confirmationText: {
    padding: '18px 20px 30px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  cancelBtn: {
    width: '180px',
    height: '54px',
    border: '1px solid #8735E1',
    borderRadius: '8px',
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    lineHeight: '22px',
    color: '#8735E1',
    padding: '0px',
    marginRight: '20px',
    textTransform: 'none',
    boxShadow: 'none',
  },
  logOutBtn: {
    width: '180px',
    height: '54px',
    border: '1px solid #F30202',
    backgroundColor: '#F30202',
    borderRadius: '8px',
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    lineHeight: '22px',
    color: '#FFFFFF',
    padding: '0px',
    textTransform: 'none',
    boxShadow: 'none'
  },
  active: {
    color: "#8735E1",
    fontSize: "20px",
    fontFamily: "ClotherBold",
    fontWeight: 700,
  },

  menuButton: {
    paddingTop: 0,
    paddingLeft: "1.5rem",
    overflow: "hidden",
    lineHeight: "8px",
  },
  menuText: {
    color: "#727272",
    fontFamily: "ClotherRegular",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    textDecoration: "none",
    lineHeight: "24px",
    margin: "12px 0px 12px 0px",
  },
  listBox: {
    borderRadius: "8px",
    background: "#fff",
    marginBottom: "25px",
  },
  breadcrumbWrapper: {
    display: 'flex',
  },
  breadcrumbItem: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#A1A1A1',
    fontWeight: 600,
    cursor: 'pointer',
  },
  navigateIcon: {
    fill: '#DCDCDC'
  },
  breadcrumbActiveItem: {
    color: "#8735E1",
    fontSize: "16px",
    lineHeight: "22px",
    fontFamily: 'ClotherBold',
    fontWeight: 700,
  },
};

export const Wrapper: any = styled(Box)({
  "& .mainList": {
    display: "flex",
    flexDirection: "column",
    minHeight: "82.5vh",
    justifyContent: "space-between",
    height: "84vh",
    overflowY: "auto",
  },
  "& .mainList::-webkit-scrollbar": {
    display: 'none',
  }
})