import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import { toast } from "react-toastify";
import {
  plusIcon,
  trueIcon,
} from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from 'react';
import { searchDefaultImage } from '../../dashboard/src/assets';

export interface ActionTrackerPostData {
  want_to_approve: string,
  id: number,
  attributes: {
    action_image: string;
    type_of_action_tracker: string;
    id: number;
    account_id: number;
    description: string;
    status: string | undefined;
    due_date: string;
    want_to_approve: string;
    action_taken: string;
    remark: string,
    reply: string,
    action_type: string,
    location_description: string,
    deficiency_number: string,
    follow_up: string,
    serial_number: string,
    created_at: string,
    area: string,
    output_image_url: string,
    image_url: string,
    profile_pic: string,
    user_name: string,
    location: string,
    supervisor_account: {
      profile_image: string;
      full_name: string;
      employee_id: number;
    },
    checkpoint: {
      non_compiled: number;
      remark: string,
      unit_of_measurement: string,
    },
    post: {
      risk_level_name: string;
      location: string;
      work_location: string;
      description: string,
      image_url: string,
      user_name: string,
      output_image_url?: string,
    },
    inspection: {
      location: string;
    },
    worker_account: {
      profile_image: string;
      full_name: string;
    }
  }
}

export interface AssignedPerson {
  label: string;
  value: string | number;
}

// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  ModalOpen: boolean;
  statusFilter: any[];
  assignedFilter: number[];
  actionTrackerPostData: ActionTrackerPostData[];
  currentPage: number;
  assignFilterName: any[];
  loaderData: boolean;
  totalCount: number;
  filteredData: any[];
  isLoaderModal: boolean;
  actionPostData: ActionTrackerPostData | null;
  isLoader: boolean,
  postID: number;
  followUp: string;
  followUpTextArea: string;
  EmployeeName: string | number;
  employeeList: any;
  is_supervisor: boolean;
  selectedStatus: string | undefined;
  statusList: any[];
  selectedDate: Date | null | string |any;
  errorModal: boolean;
  errorMessage: string;
  selectedFilters: string[];
  riskLevels: any[];
  statuses: any[];
  isActiveFilter: boolean;
  isStatusChanges: boolean;
  dummyAssignedFilters: any[],
  dummystatusFilter: any[],
  isClearAll: boolean;
  listening: boolean;
  listeningFollowUp: boolean;
  stoppedByTimeout: boolean;
  transcription: string;
  remarks: string;
  stoppedByTimeoutByFollouUp: boolean;
  reply: string;
  listeningReply: boolean;
  stoppedByTimeoutByReply: boolean;
  action_taken: string;
  clearStatusAll: boolean;
  clearAssignedAll: boolean;
  dummySelectedFilter: any[];
  listeningActionTaken: boolean;
  stoppedByTimeoutActionTaken: boolean;
  isError: boolean;
  reAssignError: string;
  type: string;
  assignPersonLoading: boolean;
  assignPersonRadio: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PerformanceTrackerController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getActionTrackerDataCallID: string = "";
  getFilteredDataAPICallID: string = "";
  getEmployeesCallID: string = "";
  updateActionTrackerWorkerAPICallID: string = "";
  recognition: any;
  advanceSearch: NodeJS.Timeout | number = 0;
  reAssignAPICallID: string = "";
  supervisorFollowUpAPICallID: string = "";
  ApproveActionAPICallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      ModalOpen: false,
      statusFilter: [],
      assignedFilter: [],
      actionTrackerPostData: [],
      riskLevels: [
        { name: "1A", color: "#21C274", backgroundColor: "#DBFFED" },
        { name: "1B", color: "#21C274", backgroundColor: "#DBFFED" },
        { name: "1C", color: "#21C274", backgroundColor: "#DBFFED" },
        { name: "1D", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "1E", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "2A", color: "#21C274", backgroundColor: "#DBFFED" },
        { name: "2B", color: "#21C274", backgroundColor: "#DBFFED" },
        { name: "2C", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "2D", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "2E", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "3A", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "3B", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "3C", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "3D", color: "#ffe52c", backgroundColor: "#FFF4DE" },
        { name: "3E", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "4A", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "4B", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "4C", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "4D", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "4E", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "5A", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "5B", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "5C", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "5D", color: "#F30202", backgroundColor: "#FFEDED" },
        { name: "5E", color: "#F30202", backgroundColor: "#FFEDED" },
      ],
      currentPage: 1,
      assignFilterName: [],
      loaderData: false,
      totalCount: 0,
      filteredData: [],
      isLoaderModal: false,
      actionPostData: null,
      isLoader: false,
      postID: 0,
      followUp: "",
      followUpTextArea: "",
      EmployeeName: configJSON.empNameOption,
      employeeList: {},
      is_supervisor: false,
      selectedStatus: configJSON.SelectStatusText,
      statusList: ["Planned", "Cancelled", "Completed", "Not My Domain"],
      selectedDate: new Date(),
      errorModal: false,
      errorMessage: "",
      selectedFilters: [],
      statuses: [
        { label: "Planned", name: "planned" },
        { label: "Due", name: "due" },
        { label: "Overdue", name: "overdue" },
        { label: "Cancelled", name: "cancelled" },
        { label: "Not my domain", name: "Not my domain" },
        { label: "Verified", name: "verified" },
        { label: "Pending review", name: "Pending review" },
      ],
      isActiveFilter: false,
      isStatusChanges: false,
      dummyAssignedFilters: [],
      dummystatusFilter: [],
      isClearAll: false,
      listening: false,
      listeningFollowUp: false,
      stoppedByTimeout: false,
      transcription: "",
      remarks: "",
      stoppedByTimeoutByFollouUp: false,
      reply: "",
      listeningReply: false,
      stoppedByTimeoutByReply: false,
      action_taken: "",
      stoppedByTimeoutActionTaken: false,
      clearStatusAll: false,
      clearAssignedAll: false,
      dummySelectedFilter: [],
      listeningActionTaken: false,
      isError: false,
      reAssignError: "",
      type: "",
      assignPersonLoading: false,
      assignPersonRadio: "yes",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async componentDidMount() {
    this.getToken();
    this.getActionTrackerAPI(1);
    const postID = await getStorageData("actionPostID")
    this.setState({ postID: postID })
    const followUp = await getStorageData("pageType");
    this.setState({ followUp: followUp });
    if (followUp !== "followUp") {
      this.getEmployeeList("")
    }
    if ("SpeechRecognition" in global || "webkitSpeechRecognition" in global) {
      this.setupSpeechRecognition();
    }
  }

  setupSpeechRecognition() {
    this.recognition = new ((global as any).SpeechRecognition ||
      (global as any).webkitSpeechRecognition)();

    if (this.recognition) {
      this.recognition.lang = "en-US";
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.onresult = (event: any) => {
        const transcription = Array.from(event.results)
          .map((result: any) => result[0].transcript)
          .join("");
        this.setState({ transcription });
      };
      const pageHiddenProp = this.getPageHiddenProp();
      if (pageHiddenProp) {
        this.addVisibilityChangeListener(pageHiddenProp);
      }
    }
  }

  addVisibilityChangeListener(pageHiddenProp: string) {
    document.addEventListener(pageHiddenProp.replace(/[H|h]idden/, '') + 'visibilitychange', () => {
      if ((document as any)[pageHiddenProp] && this.recognition) {
        this.stopRecognition();
      }
    });
  }

  stopRecognition() {
    if (this.state.listening) {
      this.recognition.stop();
      this.setState({ listening: false });
    }

    if (this.state.listeningFollowUp) {
      this.recognition.stop();
      this.setState({ listeningFollowUp: false });
    }

    if (this.state.listeningReply) {
      this.recognition.stop();
      this.setState({ listeningReply: false });
    }

    if (this.state.listeningActionTaken) {
      this.recognition.stop();
      this.setState({ listeningActionTaken: false });
    }
  }

  getPageHiddenProp() {
    const hiddenProps = ["hidden", "webkitHidden", "mozHidden"];
    for (const prop of hiddenProps) {
      if (prop in document) {
        return prop;
      }
    }
    return null;
  }
  handleAssignPersonRadio = (event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
    this.setState({ assignPersonRadio: (event.target.value) as string });
  }

  navigateToViewCourses = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "PtCoursesScreen"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  navigateToViewStudents = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "PtStudentRankingScreen"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  receive = async (_from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    const webApiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (webApiRequestCallId === this.getActionTrackerDataCallID) {
      this.setState({ loaderData: false })
      if (this.state.followUp === "followUp" || this.state.followUp === "update") {
        this.setState({ isLoader: false })
      }
      if (!responseJson.errors) {
        let data: any[] = []
        this.setState({ selectedFilters: [] })
        this.AssignListData(data, responseJson, false)
      } else {
        this.setState({ actionTrackerPostData: [], selectedFilters: [] })
      }
    }
    if (webApiRequestCallId === this.getFilteredDataAPICallID) {
      let data: any = []
      this.setState({
        loaderData: false,
      });
      this.AssignListData(data, responseJson, true)
    }
    if (webApiRequestCallId === this.getEmployeesCallID) {
      this.setEmployeeData(responseJson)
    }
    if (webApiRequestCallId === this.supervisorFollowUpAPICallID) {
      this.setState({ loaderData: false })
      if (responseJson.action_trackers) {
        this.goToListing()
        this.setState({ isError: false, errorMessage: "" })
      } else {
        this.setState({ isError: true, errorMessage: responseJson.message })
      }
    }
    if (webApiRequestCallId === this.reAssignAPICallID) {
      this.setState({ loaderData: false })
      this.ReAssignAPICallRes(responseJson)
    }
    this.updateApiCalls(webApiRequestCallId, responseJson)
  }

  ReAssignAPICallRes = (responseJson: any) => {
    if (responseJson.action_trackers) {
      this.setState({ isError: false, errorMessage: "" })
      this.goToListing()
    }
    if (responseJson.error) {
      this.setState({ isError: true, errorMessage: responseJson.error })
    }
    if (responseJson.message) {
      this.setState({ isError: true, errorMessage: responseJson.message })
    }
  }

  setEmployeeData = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({ employeeList: responseJson.data, isLoader: false, assignPersonLoading: false })
    } else {
      this.setState({ employeeList: [], isLoader: false, assignPersonLoading: false })
    }
  }

  updateApiCalls = (webApiRequestCallId: any, responseJson: any) => {
    if (webApiRequestCallId === this.ApproveActionAPICallID) {
      if (responseJson.action_trackers) {
        this.setState({ loaderData: false, isError: false, errorMessage: "" })
        this.goToListing()
      } else {
        this.setState({ loaderData: false, isError: true, errorMessage: responseJson.message })
      }
    }
    if (webApiRequestCallId === this.updateActionTrackerWorkerAPICallID) {
      this.setState({ loaderData: false })
      if (responseJson.data) {
        this.setState({ isError: false, errorMessage: "" })
        this.goToListing()
      } else {
        this.setState({ isError: true, errorMessage: responseJson.message })
      }
    }
  }

  AssignListData = async (data: ActionTrackerPostData[], responseJson: { action_trackers: { data: ActionTrackerPostData[]; meta: { pagination: { total_count: number; }; } }; assigned_to: string[]; }, isFilter: boolean) => {
    data = responseJson.action_trackers.data
    this.setState({
      actionTrackerPostData: data,
      totalCount: responseJson.action_trackers?.meta?.pagination?.total_count,
    });
    if (!isFilter) {
      this.setState({
        assignFilterName: responseJson.assigned_to,
        actionTrackerPostData: data,
      });
    }
    let actionData = await getStorageData("actionData")
    actionData = JSON.parse(actionData)
    this.updateState(actionData)
    
    if (this.state.actionPostData?.attributes.status === "verified") {
      this.setState({
        selectedStatus: 'Completed',
      })
    }
  }

  updateState = (actionData: any) => {
    this.setState({
      actionPostData: actionData,
      is_supervisor: actionData.attributes.type_of_action_tracker === "other_trackers" ? true : false,
    }, () => {
      this.setState({
        selectedDate: this.state.actionPostData?.attributes.due_date ? this.state.actionPostData?.attributes.due_date : this.state.actionPostData?.attributes.created_at,
      })
    })
  }

  filterModalOpen = () => {
    this.setState({ ModalOpen: true });
    if (this.state.selectedFilters.length > 0 || this.state.statusFilter.length > 0) {
      this.setState({ isActiveFilter: true, dummySelectedFilter: this.state.statusFilter, dummystatusFilter: this.state.statusFilter });
    } else {
      this.setState({ isActiveFilter: false });
    }
  };

  filterModalClose = () => {
    if (this.state.isClearAll && this.state.isActiveFilter) {
      this.setState({ statusFilter: this.state.dummystatusFilter, selectedFilters: this.state.dummySelectedFilter, assignedFilter: this.state.dummyAssignedFilters, isActiveFilter: true })
    } else if (this.state.isClearAll && !this.state.isActiveFilter) {
      this.setState({ statusFilter: [], selectedFilters: [], assignedFilter: [] })
    }
    if (this.state.clearStatusAll && this.state.isActiveFilter) {
      this.setState({ statusFilter: this.state.dummystatusFilter })
    } else if (this.state.clearStatusAll && !this.state.isActiveFilter) {
      this.setState({ statusFilter: [] })
    }
    if (this.state.clearAssignedAll && this.state.isActiveFilter) {
      this.setState({ assignedFilter: this.state.dummyAssignedFilters, selectedFilters: this.state.dummySelectedFilter, clearAssignedAll: false })
    } else if (this.state.clearAssignedAll && !this.state.isActiveFilter) {
      this.setState({ assignedFilter: [], selectedFilters: [] })
    }
    if (!this.state.isClearAll && !this.state.clearAssignedAll && !this.state.clearStatusAll && !this.state.isActiveFilter) {
      this.setState({ statusFilter: this.state.dummystatusFilter, assignedFilter: [], selectedFilters: [], ModalOpen: false });
    }
    this.setState({ statusFilter: this.state.dummystatusFilter, ModalOpen: false, isClearAll: false, clearStatusAll: false, clearAssignedAll: false });
  };

  handleRemoveStatusFitlter = (name: string) => {
    const filteredStatus = this.state.statusFilter.filter(
      (temp) => temp !== name
    );
    this.setState({ statusFilter: filteredStatus, currentPage: 1 });
    if (filteredStatus.length > 0 || this.state.selectedFilters.length > 0) {
      this.getFilteredDataAPI(filteredStatus);
    } else {
      this.getActionTrackerAPI(1);
    }
  };

  statusSelectFilter = (status: string) => {
    this.setState((prevState) => {
      const isSelected = [...prevState.statusFilter];
      const statusSelected = isSelected.indexOf(status);
      if (statusSelected === -1) {
        isSelected.push(status);
      } else {
        isSelected.splice(statusSelected, 1);
      }
      return { statusFilter: isSelected };
    });
  };

  clearStatusFilter = () => {
    this.setState({
      dummystatusFilter: this.state.statusFilter
    }, () => {
      this.setState({ statusFilter: [], clearStatusAll: true });
    })
  };

  clearAssignedFilter = () => {
    this.setState({
      dummySelectedFilter: this.state.selectedFilters,
      dummyAssignedFilters: this.state.assignedFilter
    }, () => {
      this.setState({ assignedFilter: [], clearAssignedAll: true, selectedFilters: [] });
    })
  };

  assignedSelectFilter = (id: Array<any>) => {
    this.setState((prevState) => {
      const isSelected: any = [...prevState.selectedFilters];
      const selectedIds: any = [...prevState.assignedFilter];
      let Selected = isSelected.indexOf(id);
      if (Selected === -1) {
        isSelected.push(id);
        selectedIds.push(id[1])
      } else {
        isSelected.splice(Selected, 1);
        selectedIds.splice(Selected, 1);
      }
      return { selectedFilters: isSelected, assignedFilter: selectedIds };
    });
  }

  getActionTrackerAPI = async (curruntPage: number) => {
    this.setState({ isLoader: true, loaderData: true })
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getActionTrackerDataCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.actionTrackerEndpoint}?page=${curruntPage}&per_page=9`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  ClearAllFilters = () => {
    this.setState({
      isClearAll: true,
      dummySelectedFilter: this.state.selectedFilters,
      dummyAssignedFilters: this.state.assignedFilter,
      dummystatusFilter: this.state.statusFilter
    }, () => {
      this.setState({
        statusFilter: [],
        selectedFilters: [],
        assignedFilter: []
      });
    })
  };

  getFilteredDataAPI = (filteredStatus: any) => {
    const { assignedFilter } = this.state;
    this.setState({ loaderData: true })
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.actionTrackerEndpoint}?page=${this.state.currentPage}&per_page=9&assigned_to=${assignedFilter}&status=${filteredStatus}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    this.getFilteredDataAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  handlePageChange = (_e: any, newPage: number) => {
    this.setState({ currentPage: newPage }, () => {
      this.getFilteredDataAPI(this.state.statusFilter);
    });
  };

  viewPost = (id: number) => {
    if (id === 0) {
      setStorageData("actionPostID", "0");
      setStorageData("actionData", JSON.stringify(this.state.actionTrackerPostData[id]))
    } else {
      setStorageData("actionPostID", id);
      setStorageData("actionData", JSON.stringify(this.state.actionTrackerPostData[id]))
    }
    if (this.state.actionTrackerPostData[id].attributes.status === "Pending review" && this.state.actionTrackerPostData[id].attributes.type_of_action_tracker === "other_trackers") {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "ActionTrackerPost");
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(msg);
      setStorageData('pageType', 'followUp');
    } else {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "ActionTrackerPost");
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(msg);
    }
  };

  navigatePage = (type: string) => {
    this.setState({ type: type })
    setStorageData('pageType', type);
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ActionTrackerPostForm");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  };

  applyFilter = () => {
    this.setState({ isActiveFilter: true, currentPage: 1 })
    if (this.state.clearStatusAll) {
      this.setState({ statusFilter: [], dummystatusFilter: [] })
      this.setState({ ModalOpen: false, clearStatusAll: false })
    }
    if (this.state.clearAssignedAll) {
      this.setState({ isActiveFilter: false, assignedFilter: [], dummyAssignedFilters: [], clearAssignedAll: false, selectedFilters: [], dummySelectedFilter: [] })
      this.setState({ ModalOpen: false })
    }
    if (this.state.isClearAll) {
      this.setState({ isActiveFilter: false, statusFilter: [], selectedFilters: [], isClearAll: false, dummyAssignedFilters: [], dummystatusFilter: [], dummySelectedFilter: [] })
      this.setState({ ModalOpen: false })
    }
    if (!this.state.isClearAll && !this.state.clearStatusAll && !this.state.clearAssignedAll) {
      this.setState({ dummyAssignedFilters: [], dummystatusFilter: [], isClearAll: false, dummySelectedFilter: [] })
      this.setState({ ModalOpen: false, isClearAll: false, clearStatusAll: false, clearAssignedAll: false });
    }
    this.setState({ currentPage: 1 })
    this.getFilteredDataAPI(this.state.statusFilter);
  };

  getStatusBackground = (statusBackground: string) => {
    switch (statusBackground) {
      case "planned":
        return "#f5f0f0";
      case "due":
        return "#FFF4DE";
      case "overdue":
        return "#FFEDED";
      case "completed":
        return "#DBFFED";
      case "cancelled":
        return "#FFE8E3";
      case "Not my domain":
        return "rgb(234 235 234)";
      case "verified":
        return "#DBFFED";
      case "Pending review":
        return "#FFF4DE";
      default:
        return "";
    }
  };

  statusTextColor = (status: string) => {
    switch (status) {
      case 'Completed':
        return '#009A4F';
      case 'planned':
        return '#A1A1A1'
      case 'overdue':
        return '#FA0000'
      case 'cancelled':
        return '#9A1C00'
      case 'due':
        return '#DF9300'
      case 'verified':
        return '#009A4F'
      case "Pending review":
        return "#DF9300";
      default:
        return 'grey'
    }
  }

  capitalizeFirstLetter = (str: string) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return ""
    }
  }

  getStatusColor = (statusFilter: any, statusName: string) => {
    return statusFilter.includes(statusName) ? "#fff" : "#8b8787";
  }

  getIcon = (statusFilter: any, statusName: string) => {
    return statusFilter.includes(statusName) ? trueIcon : plusIcon;
  }

  getAssignIcon = (assignedFilter: any, data: any) => {
    return assignedFilter.includes(data[1]) ? trueIcon : plusIcon;
  }

  getAssignFilterBackground = (data: any) => {
    return this.state.assignedFilter.includes(data[1]) ? "#8735E1" : "";
  }

  getStatusBackgroundColor = (statusFilter: any, statusName: any) => {
    return statusFilter.includes(statusName) ? "#8735E1" : "";
  }

  getAssignFilterColor = (data: any) => {
    return this.state.assignedFilter.includes(data[1]) ? "#fff" : "#8b8787";
  }

  handleTextAreaChange = (e: ChangeEvent<{ value: unknown; }>) => {
    this.setState({ followUpTextArea: e.target.value as string });
  };

  handleActionTakenChange = (e: { target: { value: string; }; }) => {
    this.setState({ action_taken: e.target.value });
  }

  handleRemarkChange = (e: { target: { value: string; }; }) => {
    this.setState({ remarks: e.target.value });
  }

  handleReplyChange = (e: { target: { value: string; }; }) => {
    this.setState({ reply: e.target.value });
  }

  handleNameChange = (e: { value: string | number; } | null) => {
    this.setState({ EmployeeName: e?.value as string, reAssignError: "" });
  };

  dateChange = (date: Date | null) => {
    this.setState({ selectedDate: date });
  };

  handleActionApprove = (e: { target: { value: string; }; }) => {
    if (this.state.actionPostData !== null) {
      this.state.actionPostData.attributes.want_to_approve = e.target.value
      this.setState({ actionPostData: this.state.actionPostData })
    }
  }

  reAssignTracker = () => {
    if (this.state.EmployeeName === "Employee Name") {
      this.setState({ reAssignError: "Please Select Assign Person" })
      return false
    }
    this.setState({ loaderData: true, reAssignError: "" })
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.actionTrackerEndpoint}/re_assign?action_tracker_id=${this.state.actionPostData?.id}&reassign_id=${this.state.EmployeeName}&due_date=${this.state.selectedDate}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    this.reAssignAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  followUpForAction = () => {
    this.setState({ loaderData: true })
    let tokenValue = localStorage.getItem("authToken");
    let data = {
      action_tracker_id: this.state.actionPostData?.attributes.id,
      follow_up: this.state.followUpTextArea
    };
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: tokenValue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.supervisorFollowUpAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  followUpForActionVerify = () => {
    this.setState({ loaderData: true })
    let tokenValue = localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: tokenValue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.supervisorFollowUpAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/action_trackers/review_action_taken?action_tracker_id=${this.state.actionPostData?.id}&want_to_approve=${this.state.assignPersonRadio}&status=${this.state.assignPersonRadio === "yes" ? "verified" : "planned"}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  approveActionTracker = () => {
    let isApproved = this.state.actionPostData?.attributes.want_to_approve === "yes" ? true : false;
    let tokenValue = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: tokenValue,
    };
    const requestData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ApproveActionAPICallID = requestData.messageId;
    requestData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.actionTrackerEndpoint}/review_action_taken?action_tracker_id=${this.state.actionPostData?.attributes.id}&want_to_approve=${isApproved}&status=verified&action_taken=${this.state.actionPostData?.attributes.action_taken}`
    );
    requestData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestData.id, requestData);
  };

  updateActionForWorker = () => {
    this.setState({ loaderData: true })
    let selectedStatus: string | undefined = ""
    if (this.state.selectedStatus === "Not My Domain") {
      selectedStatus = "Not my domain"
    } else if (this.state.selectedStatus === "Select Status") {
      selectedStatus = this.state.actionPostData?.attributes.status
    } else {
      selectedStatus = this.state.selectedStatus?.toLowerCase()
    }
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
      'Content-Type': 'application/json'
    };
    let data = {
      id: this.state.actionPostData?.attributes.id,
      status: selectedStatus,
      action_taken: this.state.action_taken ? this.state.action_taken : this.state.actionPostData?.attributes.action_taken,
      remark: this.state.remarks ? this.state.remarks : this.state.actionPostData?.attributes.remark,
      reply: this.state.reply ? this.state.reply : this.state.actionPostData?.attributes.reply,
    }

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.actionTrackerEndpoint}/${this.state.actionPostData?.attributes.id}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    this.updateActionTrackerWorkerAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getEmployeeList = (searchStr: string) => {
    this.setState({ assignPersonLoading: true })
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: tokenValue,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEmployeesCallID = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_audittrail2/audits/get_users`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  handleStatusChange = (e: ChangeEvent<{ value: unknown; }>) => {
    this.setState({ selectedStatus: e.target.value as string, isStatusChanges: true, action_taken: "", reply: "", remarks: "" })
  }

  goToListing = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "PerformanceTracker");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }

  StatusNames = (status: string) => {
    switch (status) {
      case 'Pending review':
        return 'Pending Review'
      case 'Not my domain':
        return 'Not My Domain'
      case 'completed':
        return 'Completed'
      case 'due':
        return 'Due'
      default:
        return this.capitalizeFirstLetter(status)
    }
  }

  handleRemoveAssignedFitlter = (name: string) => {
    const filteredData: any = this.state.selectedFilters.filter(
      (temp: any) => temp[0] !== name[0]
    );
    const filteredData2 = this.state.assignedFilter.filter(
      (temp: any) => temp !== name[1]
    );
    this.setState({
      currentPage: 1,
      selectedFilters: filteredData,
      assignedFilter: filteredData2,
      statusFilter: this.state.statusFilter
    }, () => {
      if (filteredData.length > 0 || this.state.statusFilter.length > 0) {
        this.getFilteredDataAPI(this.state.statusFilter);
      } else {
        this.getActionTrackerAPI(1)
      }
    });
  }

  checkborderColor = (itemRiskLevel: string) => {
    let colorChoose = "#DCDCDC";
    this.state.riskLevels.forEach((item) => {
      if (item.name == itemRiskLevel) {
        colorChoose = item.color;
      }
    });
    return colorChoose;
  };

  toggleListening = async () => {
    if (this.recognition) {
      if (this.state.listening) {
        this.setState({ stoppedByTimeout: true });
        this.recognition.stop();
      } else {
        this.setState({ stoppedByTimeout: false });
        await this.recognition.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (this.state.listening && !this.state.stoppedByTimeout) {
            this.recognition.stop();
            this.setState({ listening: false });
          }
        }, 30000);

      }
      this.setState((prevState) => ({
        listening: !prevState.listening,
      }));
    }
  };
  toggleListeningForReply = async () => {
    if (this.recognition) {
      if (this.state.listeningReply) {
        this.setState({ stoppedByTimeoutByReply: true });
        this.recognition.stop();
      } else {
        this.setState({ stoppedByTimeoutByReply: false });
        await this.recognition.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (this.state.listeningReply && !this.state.stoppedByTimeoutByReply) {
            this.recognition.stop();
            this.setState({ listeningReply: false });
          }
        }, 30000);

      }
      this.setState((prevState) => ({
        listeningReply: !prevState.listeningReply,
      }));
    }
  }

  toggleListeningForActionTaken = async () => {
    if (this.recognition) {
      if (this.state.listeningActionTaken) {
        this.setState({ stoppedByTimeoutActionTaken: true });
        this.recognition.stop();
      } else {
        this.setState({ stoppedByTimeoutActionTaken: false });
        await this.recognition.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (this.state.listeningActionTaken && !this.state.stoppedByTimeoutActionTaken) {
            this.recognition.stop();
            this.setState({ listeningActionTaken: false });
          }
        }, 30000);

      }
      this.setState((prevState) => ({
        listeningActionTaken: !prevState.listeningActionTaken,
      }));
    }
  };

  closeErrorModal = () => {
    this.setState({ errorMessage: "", isError: false })
  }

  goToUserProfile = (userId: number | undefined) => {
    setStorageData("otherUserId", userId)
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Profile");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }

  toggleListeningForFollowUP = async () => {
    if (this.recognition) {
      if (this.state.listeningFollowUp) {
        this.setState({
          stoppedByTimeoutByFollouUp: true
        });
        this.recognition.stop();
      } else {
        this.setState({
          stoppedByTimeoutByFollouUp: false
        });
        await this.recognition.start();
        this.setState({
          transcription: ""
        });
        setTimeout(() => {
          if (this.state.listeningFollowUp && !this.state.stoppedByTimeoutByFollouUp) {
            this.recognition.stop();
            this.setState({
              listeningFollowUp: false
            });
          }
        }, 30000);

      }
      this.setState((prevState) => ({
        listeningFollowUp: !prevState.listeningFollowUp,
      }));
    }
  };

  goToActionTracker = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "PerformanceTracker");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }

  goToPostPage = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ActionTrackerPost");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }

  getAssignPersonOptionsList = () => {
    const { employeeList, assignPersonLoading } = this.state;
    const options: AssignedPerson[] = [];
    !assignPersonLoading && employeeList?.length > 0 && employeeList?.forEach((item: { "full name": string; id: number; }) => {
      if (item["full name"] !== null && item["full name"] !== "") {
        options.push({
          label: `${item["full name"].trim()}`,
          value: item.id
        })
      }
    });
    return options.sort((a, b) => a.label.localeCompare(b.label));
  }

  handleAssignPersonInputChange = (newValue: string) => {
    if (newValue !== "") {
      clearTimeout(this.advanceSearch);
      this.advanceSearch = setTimeout(() => {
        this.getEmployeeList(newValue);
      }, 500);
    }
  }

  getPostImage = (actionData: ActionTrackerPostData): string => {
    let image = '';
    const data = actionData.attributes;
    if (data.action_image?.length > 0) {
      image = data.action_image;
    } else if (data?.post) {
      image = data.post?.output_image_url ? data?.post?.output_image_url : data?.post.image_url;
    } else {
      image = data?.output_image_url ? data?.output_image_url : data?.image_url;
    }
    return image;
  }

  getPostUserName = (actionData: ActionTrackerPostData): string => {
    let username = '';
    const data = actionData.attributes;
    if (data.worker_account) {
      username = data.worker_account.full_name;
    } else if (data.user_name) {
      username = data.user_name;
    }
    return username;
  }

  getProfileImage = (actionData: ActionTrackerPostData): string => {
    let image = '';
    const data = actionData.attributes;
    if (data.profile_pic) {
      image = data.profile_pic;
    } else if (data.worker_account) {
      image = data.worker_account.profile_image;
    } else {
      image = searchDefaultImage;
    }
    return image;
  }

  getPostLocation = (actionData: ActionTrackerPostData): string => {
    let location = '';
    const data = actionData.attributes;
    if (data.location) {
      location = data.location;
    } else if (data.post) {
      location = data.post.location;
    } else {
      location = "No Location";
    }
    return location;
  }
  // Customizable Area End
}
